import React, { useEffect } from "react";
import DialogMedium from "../DialogMedium";
import { Button, Grid } from "@mui/material";
import TextField from "../FormFields/TextField";
import { useForm } from "react-hook-form";
import { apiSollar } from "../../services/axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import {
  loadRequestUpdateStatus,
} from "../../store/ducks/telemedicineAttendances/actions";
import {
  closePopup,
  confirmPopup,
  loadReasonDischarge,
  loadRequest,
} from "../../store/ducks/reasonDischarge/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { useNavigate } from "react-router-dom";
import AutocompleteField from "../FormFields/AutocompleteField";
import PaginatedAutocompleteField from "../FormFields/PaginatedAutocompleteField";

interface IReasonDischargeProps {
  open: boolean;
  currentAttendance: any;
  options: any;
  handleClose?: () => void;
}

function ReasonDischarge({ open, handleClose, currentAttendance, options }: IReasonDischargeProps) {
  const navigate = useNavigate();
  const userState = useSelector((state: ApplicationState) => state.users);
  const dispatch = useDispatch();
  const validations = yup.object().shape({
    reason_discharge: yup.object().required("É requerido"),
    diagnosis_discharge: yup.object().required("É requerido"),
  });

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      reason_discharge: null,
      diagnosis_discharge: null,
    },
    resolver: yupResolver(validations),
  });

  function handleCancel() {
    // apiSollar.defaults.headers["signer-auth-token"] = null;
    // Cookies.set("signer-auth-token", null);
  }

  // const requestReasonDischargeState = useSelector(
  //   (state: ApplicationState) => state.reasonDischarge.list.data
  // );

  // console.log(requestReasonDischargeState?.list?.data)

  async function getCIDs(params?: any) {
    try {
      const response = await apiSollar.get("/cid/integration/show", {
        params,
      });
      return response.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <>
      <DialogMedium
        title={"Alta do atendimento"}
        open={open}
        fullWidth={true}
        maxWidth="sm"
        handleClose={() => {
          !!handleClose && handleClose();
          dispatch(closePopup());
        }}
      >
        <Grid
          container
          spacing={2}
          component="form"
          onSubmit={handleSubmit((values) => {
            const reasonDischarge: any = values.reason_discharge
            const dignoseDischarge: any = values.diagnosis_discharge
            console.log(values)
            dispatch(
              loadRequestUpdateStatus({
                _id: currentAttendance?._id,
                finished_by: userState.data._id,
                patient_name: currentAttendance?.patient_name,
                birth_date: currentAttendance?.birthdate,
                address_header: currentAttendance?.address_header,
                address_footer: currentAttendance?.address_footer,
                gender: currentAttendance?.gender,
                mother_name: currentAttendance.mother_name,
                telemedicine_attendance_id: currentAttendance?.id_attendance,
                age: currentAttendance?.age,
                sus_card: currentAttendance?.sus_card,
                health_insurance: currentAttendance?.health_insurance,
                origin: currentAttendance?.origin,
                date_impress: currentAttendance?.date_impress,
                password_panel: currentAttendance?.password_panel,
                medical_opinion_id: "0",
                reason_discharge_id: reasonDischarge.code,
                diagnosis_discharge_id: dignoseDischarge.cid_id
              })
            );
            dispatch(confirmPopup());
            navigate(`/telemedicine/A`);
          })}
        >
          <Grid item xs={12} mb={1}>
            <AutocompleteField
              control={control}
              name="reason_discharge"
              label="Motivos de alta"
              options={options}
              optionLabelKey={"describe"}
              // optionCompareKey={"code"}
              // customOnChange={() => setValue("specialties", [])}
              required
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <PaginatedAutocompleteField
                control={control}
                required
                label="CID"
                name="diagnosis_discharge"
                queryKey="cids"
                filterKey="describe"
                service={async (params) => {
                  const regex = /^(?=.*[A-Z])(?=.*\d).+$/;
                  let code, describe;
                  params.describe = params.describe?.toUpperCase();
                  if (!regex.test(params.describe)) {
                    describe = params.describe;
                  } else {
                    code = params.describe;
                    delete params.describe;
                  }
                  const customParams = { describe, code, ...params };
                  return getCIDs(customParams);
                }}
                AutocompleteProps={{
                  getOptionLabel: (option: any) =>
                    `${option.cid_id ? option.cid_id + " -" : ""} ${option.name}`,
                  noOptionsText: "Sem resultados. Tente outra busca"
                }}
                optionLabelKey="name"
              />
          </Grid>
          <Grid item xs={3}>
            <Button type="submit" variant="contained">
              Confirmar
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => {
                handleCancel();
                !!handleClose && handleClose();
                dispatch(closePopup());
              }}
              color="error"
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
    </>
  );
}

export default ReasonDischarge;
