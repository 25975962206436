import { action } from "typesafe-actions";
import {
  ReasonDischargeTypes,
  IReasonDischargeGetTokenResponse,
  ReasonDischargeList,
  LoadRequestParams
} from "./types";

export const loadRequest = (body: {
  password: string;
  fiscal_number: string | number;
}) => action(ReasonDischargeTypes.LOAD_REQUEST, body);

export const loadSuccess = (data: IReasonDischargeGetTokenResponse) =>
  action(ReasonDischargeTypes.LOAD_SUCCESS, data);

export const openPopup = () => action(ReasonDischargeTypes.OPEN_POPUP);

export const closePopup = () => action(ReasonDischargeTypes.CLOSE_POPUP);

export const confirmPopup = () => action(ReasonDischargeTypes.CONFIRM_POPUP);

export const loadFailure = () => action(ReasonDischargeTypes.LOAD_FAILURE);

export const loadReasonDischarge = (params: LoadRequestParams = {}) => action(ReasonDischargeTypes.LOAD_REASON_DISCHARGES, { params });

export const loadReasonDischargeFailure = () => action(ReasonDischargeTypes.LOAD_REASON_DISCHARGES_FAILURE);

export const loadReasonDischargeSuccess = (data: ReasonDischargeList) => action(ReasonDischargeTypes.LOAD_REASON_DISCHARGES_SUCCESS, { data });
