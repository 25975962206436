export enum ReasonDischargeTypes {
  OPEN_POPUP = "@reasonDischarge/OPEN_POPUP",
  CLOSE_POPUP = "@reasonDischarge/CLOSE_POPUP",
  CONFIRM_POPUP = "@reasonDischarge/CONFIRM_POPUP",
  LOAD_REQUEST = "@reasonDischarge/LOAD_REQUEST",
  LOAD_SUCCESS = "@reasonDischarge/LOAD_SUCCESS",
  LOAD_FAILURE = "@reasonDischarge/LOAD_FAILURE",
  LOAD_REASON_DISCHARGES = "@reasonDischarge/LOAD_REASON_DISCHARGES",
  LOAD_REASON_DISCHARGES_SUCCESS = "@reasonDischarge/LOAD_REASON_DISCHARGES_SUCCESS",
  LOAD_REASON_DISCHARGES_FAILURE = "@reasonDischarge/LOAD_REASON_DISCHARGES_FAILURE",
}

export interface ReasonDischargeState {
  isOpen: boolean;
  list?: ReasonDischargeList;
  loading: boolean;
  success: boolean;
  error: boolean;
}

export interface IReasonDischargeGetTokenPayload {
  type: typeof ReasonDischargeTypes.LOAD_REQUEST;
  payload: {
    fiscal_number: string | number;
    password: string;
  };
}

export interface IReasonDischargeGetTokenResponse {
  active: boolean;
  _id: string;
  user_id: string,
  token: string,
  expiration_token: string,
  created_at: string,
  updated_at: string,
}

export interface ReasonDischargeInterface {
  code: string;
  describe: string;
}


export interface ReasonDischargeList {
  data: ReasonDischargeInterface[];
  limit: string;
  page: string;
  total: number;
}

export type LoadRequestParams = Partial<Omit<ReasonDischargeList, 'data'>>
