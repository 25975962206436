import { Reducer } from "redux";
import { ReasonDischargeState, ReasonDischargeTypes } from "./types";

export const INITIAL_STATE = {
  isOpen: false,
  data: null,
  loading: false,
  success: false,
  error: false,
};

const reducer: Reducer<ReasonDischargeState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ReasonDischargeTypes.OPEN_POPUP:
      return { ...state, isOpen: true };
    case ReasonDischargeTypes.CLOSE_POPUP:
      return { ...state, isOpen: false };
    case ReasonDischargeTypes.CONFIRM_POPUP:
      return { ...state, isOpen: false };
    case ReasonDischargeTypes.LOAD_REQUEST:
      return { ...state, loading: true, success: false };
    case ReasonDischargeTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isOpen: false,
        loading: false,
        success: true,
        error: false,
      };
    case ReasonDischargeTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, success: false };
     case ReasonDischargeTypes.LOAD_REASON_DISCHARGES:
      return { ...state, loading: true };
    case ReasonDischargeTypes.LOAD_REASON_DISCHARGES_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        loading: false,
      };
    case ReasonDischargeTypes.LOAD_REASON_DISCHARGES_FAILURE:
      return {
        ...state, loading: false, error: true, success: false,
        list: {
          data: [],
          limit: '10',
          page: '1',
          total: 0
        }
      };
    default:
      return state;
  }
};

export default reducer;
