import { applyMiddleware, compose, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { PrescriptionState } from "./ducks/prescripition/types";
import { ProfessionState } from "./ducks/professions/types";

import { AllergiesState } from "./ducks/allergies/types";
import { AntibioticState } from "./ducks/antibiotic/types";
import { AreaState } from "./ducks/areas/types";
import { AttachmentState } from "./ducks/attachment/types";
import { AttachmentsIntegrationState } from "./ducks/attachmentsIntegration/types";
import { AttestState } from "./ducks/attest/types";
import { CareState } from "./ducks/cares/types";
import { CompanyState } from "./ducks/companies/types";
import { CouncilState } from "./ducks/councils/types";
import { CustomerLogsState } from "./ducks/customerLogs/types";
import { CustomerState } from "./ducks/customers/types";
import { DocumentGroupState } from "./ducks/documentGroups/types";
import { DocumentState } from "./ducks/documents/types";
import { ExamsState } from "./ducks/exams/types";
import { FormState } from "./ducks/forms/types";
import { LayoutState } from "./ducks/layout/types";
import { LoginState } from "./ducks/login/types";
import { LogoState } from "./ducks/logo/types";
import { MeasurementsState } from "./ducks/measurements/types";
import { MessageState } from "./ducks/message/types";
import { OpinionState } from "./ducks/opinions/types";
import { PatientState } from "./ducks/patients/types";
import { QrCodeState } from "./ducks/qrCode/types";
import { SpecialtyState } from "./ducks/specialties/types";
import { TelemedicineState } from "./ducks/telemedicine/types";
import { UserState } from "./ducks/users/types";
import { DigitalSignatureState } from "./ducks/digitalSignature/types";
import { ReasonDischargeState } from "./ducks/reasonDischarge/types";
import { DataSyncState } from "./ducks/integrationDocuments/types";
import { PanelState } from "./ducks/panel/types";
import { RevaluationState } from "./ducks/revaluation/types";
import combinedReducers from "./ducks/rootReducer";
import rootSaga from "./ducks/rootSaga";
import { InitialState } from "./ducks/states";
import { TelemedicineAttendanceState } from "./ducks/telemedicineAttendances/types";
export interface ApplicationState {
  areas: AreaState;
  login: LoginState;
  cares: CareState;
  companies: CompanyState;
  customers: CustomerState;
  councils: CouncilState;
  documents: DocumentState;
  documentGroups: DocumentGroupState;
  patients: PatientState;
  specialties: SpecialtyState;
  prescription: PrescriptionState;
  users: UserState;
  profession: ProfessionState;
  layout: LayoutState;
  message: MessageState;
  allergies: AllergiesState;
  measurements: MeasurementsState;
  qrCode: QrCodeState;
  antibiotic: AntibioticState;
  exams: ExamsState;
  attest: AttestState;
  logo: LogoState;
  telemedicine: TelemedicineState;
  attachments: AttachmentState;
  forms: FormState;
  customerLogs: CustomerLogsState;
  attachmentsIntegration: AttachmentsIntegrationState;
  opinion: OpinionState;
  opinionAnswer: OpinionState;
  panel: PanelState;
  revaluation: RevaluationState;
  telemedicineAttendances: TelemedicineAttendanceState;
  digitalSignature: DigitalSignatureState;
  // reasonDischarge: {
  //   isOpen: boolean;
  //   data: { description: string; code: string }[];
  //   loading: boolean;
  //   success: boolean;
  //   error: boolean;
  // };
  reasonDischarge: ReasonDischargeState;
  integrationDocuments: DataSyncState;
}

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const store: Store = createStore(
  combinedReducers,
  InitialState,
  compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export default store;
